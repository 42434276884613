.tier-card {
    /* Spacing */
    min-width: 290px;
    width: 80%;
    /* min-height: 480px; */
    padding-top: 30px;
    padding-right: 36px;
    padding-bottom: 30px;
    padding-left: 36px ;
    float: none;
    margin: auto;
    margin-top: 48px;

    background: #F5F5F5;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 10px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    
    /* Fonts */
    font-family: Poppins;
    font-weight: 500;
    letter-spacing: 0;
    text-align: left;
    color: #020202
}

.tier-card--selected {
    /* Spacing */
    width: 100%;
    min-height: 533px;
    padding-top: 30px;
    padding-right: 36px;
    padding-bottom: 30px;
    padding-left: 36px;
    float: left;
    margin-top: 48px;

    background: #FFFFFF;
    border: 2px solid #ED197E;
    box-shadow: 0px 10px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    
    /* Fonts */
    font-family: Poppins;
    font-weight: 500;
    letter-spacing: 0;
    text-align: left;
    color: #020202
}

.tier-card__change-plan-box {
    position: relative;
    width: 100%;
}

.tier-card__change-plan-btn {
    float: right;
    color: #ED197E;
    cursor: pointer;
    text-decoration: underline;
}

.tier-card__h-m {
    font-size: 24px;
    line-height: 36px;
}

.tier-card__h-s {
    font-size: 16px;
    line-height: 24px;
    color: #020202BF;
}

.tier-card__h-xs {
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    margin: 0;
}

.tier-card__name {
    margin-bottom: 0px;
}

.tier-card__listings {
    margin-top: 0px;
    color: rgb(58, 58, 58);
}

.tier-card__attn {
    /* border-bottom: rgba(0, 0, 0, 0.25) solid 1px; */
    height: 60px;
}

.tier-card__attn__super-s {
    float:left;
    font-size: 20px;
    margin: 0;
}

.tier-card__attn__strong {
    float:left;
    font-size: 40px;
    font-weight: bold;
    line-height: 24px;
    margin: 0;
}

.tier-card__attn__sub-s {
    float:left;
    font-size: 20px;
    margin: 0;
    line-height: 16px; 
    margin-left: 1.6px;
    padding-top: 12px;
}

.tier-card__sub-btn {
    background: #ED197E;
    border-radius: 10px;
}

.tier-card__features {
    margin-top:3px;
}

@media screen and (min-width: 1000px) {
    .tier-card {
        height: 520px;
        width: 348px;
        float: left;
        margin-left: 64px;
        margin-top: 22.5px;
    }

    .tier-card--selected {
        width: 360px;
        height: 400px;
        float: left;
        margin-left: 64px;
        margin-top: 0;
    }
}

@media screen and (min-width: 1300px) {
    .tier-card {
        height: 520px;
        width: 348px;
        float: left;
        margin-left: 64px;
        margin-top: 22.5px;
    }

    .tier-card--selected {
        width: 380px;
        height: 400px;
        float: left;
        margin-left: 64px;
        margin-top: 0;
    }
}

@media screen and (min-width: 1400px) {
    /* TODO: fixes required to match each screen-size tier card height:width ratio (in */
    .tier-card {
        height: 520px;
        width: 348px;
        float: left;
        margin-left: 64px;
        margin-top: 22.5px;
    }

    .tier-card--selected {
        width: 400px;
        height: 500px;
        float: left;
        margin-left: 64px;
        margin-top: 0;
    }
}

@media screen and (min-width: 1500px) {
    .tier-card {
        /* Spacing */
        width: 348px;
        height: 520px;
        float: left;
        margin-left: 64px;
        margin-top: 22.5px;
    
        background: #F5F5F5;
        border: 1px solid #E5E5E5;
        box-shadow: 0px 10px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        
        /* Fonts */
        font-family: Poppins;
        font-weight: 500;
        letter-spacing: 0;
        text-align: left;
        color: #020202
    }
    
    .tier-card--selected {
        /* Spacing */
        width: 427px;
        height: 533px;
        float: left;
        margin-left: 64px;
        margin-top: 0;
    
        background: #FFFFFF;
        border: 2px solid #ED197E;
        box-shadow: 0px 10px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        
        /* Fonts */
        font-family: Poppins;
        font-weight: 500;
        letter-spacing: 0;
        text-align: left;
        color: #020202
    }
}