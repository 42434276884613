.login-form {
  max-width: 600px;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}

.login-form__forgot-pass {
  float: left;
  font-size: 13px;
  text-decoration: underline;
  color: #dd4c91;
  margin-top: 4px;
  margin-bottom: 10px;
  cursor: pointer;
}

.login-form__login-prompt {
  font-weight: normal;
  color: #02020282;
}

.login-form__signup-btn {
  display: inline-block;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  padding-left: 1em;
  padding-right: 1em;
}

.login-form__email-icon {
  float: left;
  height: 1.5em;
  margin-top: 9%;
  margin-right: 0.5em;
}

.login-form__signup-link {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: block;
  z-index: 1;
  cursor: pointer;
}

.login-form__signup-text {
  float: left;
  color: black;
}
