.register-form {
  text-align: center;
}

.register-form__left {
  width: 100%;
  display: inline-block;
}

.register-form__right {
  width: 45em;
  background-color: black;
  display: inline-block;
  color: white;
  text-align: center;
}

.register-form__inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  /* width: 50vw; */
  margin: auto;
  text-align: center;
}

.register-form__next {
  height: 54px;
  width: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;

  /* identical to box height */

  color: #ffffff;

  background: #dd4c91;
  border-radius: 10px;
  border: none;
}

.register-form__login-prompt {
  font-style: normal;
  font-weight: 400;
  font-size: 1.3em;
  line-height: 1.7em;
  color: #020202;
  margin-right: 0.3em;
}

.register-form__dd-sign {
  height: 531px;
  width: 513px;
}
