/* || CREATE-REACT-APP PREMADE CSS */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* CREATE-REACT-APP PREMADE CSS */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");

.pointer {
  cursor: pointer;
}
/* GLOBAL AND MODULAR CLASSES */
.main-app {
  /* width: 90%; */
  /* margin: auto; */
}

.loading-gif {
  width: 5%;
}

.pink-btn--large {
  background: #dd4c91;
  border: none;
  border-radius: 10px;
  height: 2.7em;
  width: 100%;

  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  cursor: pointer;
}

.pink-btn--small {
  background: #dd4c91;
  border-radius: 50px;
  border: none;
  /* height: 1.8em;
  width: 4.1em; */
  padding: 0.2em 0.6em 0.2em 0.6em;
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
}

.dd-logo--med {
  width: 120px;
}

/* <span> tag that forms a line across ~1/2 the width of the parent element */
.pseudo-line_full-span {
  display: inline-block;
  height: 1px;
  width: 100%;
  margin-bottom: 0.48em;
  background-color: darkgrey;
}

/* <span> tag that forms a line across ~1/2 the width of the parent element */
.pseudo-line_half-span {
  display: inline-block;
  height: 1px;
  width: 42%;
  margin-bottom: 0.48em;
  background-color: grey;
}

.pseudo-line_text {
  font-size: 1.3em;
  color: grey;
  margin-left: 4px;
  margin-right: 4px;
}

/* TODO: Cancel event propogation on mouse button up 
(case: user highlights text then releases button while pointing at cover */
.page-cover {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(3px);
  width: 100vw;
  height: 100vh;
  text-align: center;
}

/* <p> tag footer such as '*required' */
.asterisk-footer {
  font-size: 0.8em;
  margin: 0;
  color: grey;
  text-align: left;
}
