/* .settings-bar {
  width: 100%;
  max-width: 967px;
  float: left;
  margin-left: 25px;
  background-color: #F2F2F2;
  border-radius: 10px;
}

  .settings-bar_tab-label {
    font-size: 20px;
    width: 25%;
    float: left;
    text-align: center;
  } */

.settings-bar {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 967px;
  height: 50px;
  background-color: #f2f2f2;
  border-radius: 10px;
}

.settings-bar_tab-label {
  color: #828282;
  font-weight: 500;
  font-size: 12px;
  margin: 0;
  line-height: 30px;
  text-align: center;
}

.settings-bar_tab-label:hover {
  color: black;
  cursor: pointer;
}

.settings-bar_tab-container {
  width: 25%;
  height: 30px;
  margin-top: 10px;
}

.settings-bar_tab-container:not(:last-child) {
  border-right: 1px solid #e0e0e0;
}

.settings-body {
  width: 100%;
  max-width: 1306px;
  background-color: #f2f2f2;
  border-radius: 10px;

  margin-top: 10px;
  padding-top: 14px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
}

.settings-body__header {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0px;
}

.settings-body__grid {
  margin-top: 40px;
}

.settings-body__genie-boxes {
  border: 1px solid rgb(229, 229, 229);
  border-radius: 10px;
  background-color: #ffffff;
  float: left;
  margin-top: 20px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
}

.change-pass-btn {
  font-weight: 500;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
  color: #dd4c91;
}

.changesub-box {
  margin: auto;
  margin-top: 50px;
  width: 80vw;
  height: 80vh;
  padding-top: 50px;
  padding-bottom: 50px;
  background: white;
  border-radius: 10px;
  overflow-y: scroll;
}

@media screen and (min-width: 500px) {
  /* One more resize down for tiny width screens */

  .settings-body {
    margin-top: 10px;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 80px;
    padding-right: 80px;
  }

  .settings-body__header {
    font-size: 26px;
    font-weight: 500;
    line-height: 48px;
  }

  .settings-bar {
    height: 71px;
  }

  .settings-bar_tab-container {
    width: 25%;
    height: 50px;
    margin-top: 10px;
  }

  .settings-bar_tab-label {
    color: #828282;
    font-weight: 500;
    font-size: 16px;
    margin: 0;
    line-height: 50px;
  }

  .settings-body__genie-boxes {
    float: left;
    width: 100%;
    padding-bottom: 30px;
    padding-left: 36px;
    padding-right: 36px;
    padding-top: 30px;

    margin-top: 20px;
  }
}

@media screen and (min-width: 1000px) {
  .settings-body__genie-boxes {
    float: right;
    height: 480px;
    width: 340px;
    padding-bottom: 30px;
    padding-left: 36px;
    padding-right: 36px;
    padding-top: 30px;

    margin-top: 0px;
  }
}

@media screen and (min-width: 1100px) {
  .settings-body {
    padding-top: 54px;
    padding-bottom: 70px;
    padding-left: 60px;
    padding-right: 60px;
  }

  .settings-body__header {
    font-size: 32px;
    font-weight: 500;
    line-height: 48px;
  }

  .settings-bar_tab-label {
    color: #828282;
    font-weight: 500;
    font-size: 20px;
    margin: 0;
    line-height: 50px;
  }

  .settings-body__genie-boxes {
    float: right;
    height: 480px;
    width: 400px;
    padding-bottom: 30px;
    padding-left: 36px;
    padding-right: 36px;
    padding-top: 30px;
  }
}

@media screen and (min-width: 1300px) {
  .settings-body {
    padding-top: 93px;
    padding-bottom: 93px;
    padding-left: 126px;
    padding-right: 188px;
  }

  .settings-body__genie-boxes {
    float: right;
    height: 533px;
    width: 450px;
    padding-bottom: 30px;
    padding-left: 36px;
    padding-right: 36px;
    padding-top: 30px;
  }
}
