.subscription-form {
    text-align: center;
}

.subscription-form__options {
    margin-top: 1em;
    max-width: 427px;
    display: inline-block;
    text-align: center;
}

.subscription-form__no-option {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 32px;
    text-decoration-line: underline;
    cursor: pointer;
    color: #DD4C91;
}

@media screen and (min-width: 1500px) {
    .subscription-form__options {
        max-width: 1379px;
    }
}