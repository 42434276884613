/* .nav-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  z-index: 1;
} */
.dashboard-wrapper {
  --nav-width: 150px;
  width: 100%;
  display: grid;
  grid-template-columns: var(--nav-width) 1fr;
}
.nav-layer {
}

/* topbar mobile settings START */
.topbar {
  max-width: 300px;
  display: grid;
  grid-template-columns: 1fr 6fr;
  justify-content: center;
  align-content: center;
  align-items: center;

  font-family: "Anton";
  /* grid-template-rows: 1fr 1fr; */
}
.topbar-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
}
.topbar_caption {
  font-family: "Roboto";
}
.topbar_more {
  height: 40px;
  margin-top: 14px;
  margin-right: 18px;
  float: right;
  cursor: pointer;
}

.topbar_icon {
  float: left;
  width: 50px;
  margin-top: 9px;
  margin-left: 40px;
  margin-right: 8px;
}

.topbar_textbox {
  display: inline-block;
}

.topbar_textbox_header {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 44px;
}

.topbar_textbox_txt {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #828282;
}
/* topbar mobile settings END */

/* navmenu mobile settings START */
.navmenu {
  /* base settings */
  background-color: #f5f5f5;
  text-align: center;
  /* base settings */
  padding-left: 0.3rem;
  width: 100%;
  height: 100vh;
  position: relative;
}

.navmenu_close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 50px;
  cursor: pointer;
}

.navmenu_link {
  /* base settings */
  margin-bottom: 1.2em;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  /* base settings */

  /* display: inline-block; */
  /* width: 70%; */
}

.navmenu_logo {
  width: 70px;
}

.navmenu_link_img {
  /* base settings */
  max-width: 60px;
  /* float: left; */
  /* base settings */

  width: 60px;
  /* float: left; */
  margin-right: 16px;
}

.navmenu_link_label {
  margin: 0;
  height: 60px;
  line-height: 60px;
  text-align: left;
}
/* navmenu mobile settings END */

/* dashboard_body */
.dashboard_body {
  /* position: absolute; */
  /* top: 0;
  left: 0;
  width: 100%;
  padding-top: 100px;
  text-align: center; */
  padding-top: 25px;
}

.dashboard_body_grid {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
}

.dashboard_graph {
  max-width: fit-content;
  width: 80%;
  margin-top: 10px;
}

.nav-menu-icon {
  position: fixed !important;
  justify-content: flex-start;

  top: 10px;
  left: 10px;
  right: 0;
}
/* ------------- Big Screen START ------------- */
/* TODO: Consider even smaller min-height */
@media screen and (min-width: 1024px) {
  /* topbar desktop settings START */
  .topbar {
  }

  .topbar_more {
    height: 60px;
    margin-top: 18px;
    margin-right: 18px;
    float: right;
    cursor: pointer;
  }

  .topbar_icon {
    float: left;
    margin-top: 6px;
    margin-left: 40px;
    margin-right: 8px;
  }

  .topbar_textbox {
    display: inline-block;
  }

  .topbar_textbox_header {
    margin: 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 60px;
  }

  .topbar_textbox_txt {
    margin: 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #828282;
  }
  /* topbar desktop settings END */

  /* navmenu big settings START */
  .navmenu {
    min-height: 100vh;
    height: 100%;
    width: var(--nav-width);

    display: block;
  }

  .navmenu_close {
    display: none;
  }

  .navmenu_top {
    /* position: absolute; */
    top: 0;
    width: 100%;
  }

  .navmenu_bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  /* .navmenu_link {
    display: block;
    padding: 0;
    width: 100%;
    margin-bottom: 26px;
  } */

  .navmenu_link_img {
    float: none;
    width: 60px;
    margin: 0;
  }

  .navmenu_link_label {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 0.6em;
    height: 0.6em;
    line-height: 0.6em;
    text-align: center;
    margin: -0.6em 0em 0em 0em;
  }
  /* navmenu big settings END */

  .topbar {
  }

  .topbar_more {
    display: none;
  }

  .dashboard_body {
    width: 100%;
    padding-top: 40px;
  }
}
/* ------------- Big Screen END ------------- */

@media screen and (max-width: 1024px) {
  .dashboard-wrapper {
    grid-template-columns: 1fr;
  }
}
