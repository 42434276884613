.forgot-pass-window {
  z-index: 11;
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  
  max-width: 80%;
  background-color: white;
  border-radius: 20px;
  padding: 20px 40px 60px 40px;

  font-size: 12px;
}

@media screen and (min-width: 600px) {
  .forgot-pass-window {
    max-width: 500px;
    font-size: 16px;
  }
}