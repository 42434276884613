.subbed-geniebox-listing {
  background: #FFFFFF;
  height: 96px;
  padding-top: 11px;
  padding-left: 20px;
  border: 1px solid #E5E5E5;
  border-radius: 10px;
  margin-top: 18px;
}

  .subbed-geniebox-listing__num {
    float: left;
    text-align: left;
    background: #D9D9D9;
    border-radius: 10px;

    width: 54px;
    height: 48px;
    padding-left: 8px;
    padding-top: 9px;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
  }

  .subbed-geniebox-listing__location {
    float: left;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: rgba(2, 2, 2, 0.32);
    margin-left: 8px;
  }

  .subbed-geniebox-listing__unsub {
    float: right;
    margin-right: 12px;

    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-decoration-line: underline;
    cursor: pointer;
    color: #DD4C91;
  }

@media screen and (min-width: 520px) {
  .subbed-geniebox-listing {
    height: 70px;
    padding-top: 11px;
    padding-left: 20px;
    margin-top: 18px;
  }
}

@media screen and (min-width: 650px) {
  .subbed-geniebox-listing__num {
    width: 70px;
    height: 48px;
    padding-left: 8px;
    padding-top: 9px;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
  }
}