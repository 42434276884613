/* Mobile/responsive */
.text-field {
    font-size: 12px;
    font-weight: 500;

    width: 100%;
    display: inline-block;
}

/* NOTE: decided to make "half" fields return to full size on mobile display */
.text-field--half {
    font-size: 12px;
    font-weight: 500;

    width: 100%; 
}

.text-field--half-right {
    font-size: 12px;
    font-weight: 500;

    width: 100%;
}

.text-field__label {
    margin-bottom: 2px;
    margin-top: 0;
    text-align: left;
    font-weight:400;
    font-size: 16px;
}

.text-field__option-btn {
    float: right;
    margin-bottom: 2px;
    margin-top: 0;
    text-align: right;
    font-weight: 500;
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
    color: #DD4C91;
}

.text-field__input {
    width: 100%;
    min-width: 194.5px;
    height: 59.5px;
    background: #FFFFFF;
    border: 1px solid rgba(2, 2, 2, 0.32);
    border-radius: 10px;
    padding-left: 14px;
    margin-bottom: 4px;

    font-size: 13px;
    font-family: 'Poppins';
    font-weight: 100;
}

.text-field__error {
    color: red;
    font-size: 13px;
    font-weight: 500;
    text-align: left;
    height: 13px;
    margin: 0;
}

@media screen and (min-width: 500px) { 
}

@media screen and (min-width: 850px) { 
    /* Fixes for half-screen textbox getting too small - making all of them equal */
    .text-field--half {
        width: 49%;
        float: left;
    }
    
    .text-field--half-right {
        width: 49%;
        float: right;
    }
}